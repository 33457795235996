import './Privacy.scss';

export const Privacy = () => {
    return (
        <div className='container bg-transparent'>
            <h1 className='text-center fw-b'><b>Privacy Policy</b></h1>
            <p className='text-center mb-3'>Last Revision: January 20, 2025</p>

            <section className='mb-3'>
                <h2 className='fw-bold'>1. Introduction</h2>
                <p>
                    At Birbli, we prioritize your privacy and are committed to safeguarding your personal information.
                    This Privacy Policy outlines the types of information we collect and how we use it. By using the
                    Birbli app, you consent to the practices described in this policy and agree to its terms and
                    conditions. If you have any questions or concerns about our Privacy Policy, please feel free to
                    contact us at <a href='mailto:birb@birbli.com'>birb@birbli.com</a>.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>2. Information We Collect</h2>
                <p className='mb-3'>
                    Birbli collects specific personal information to enhance your experience and provide our services
                    effectively:
                </p>
                <ul className='privacy-list'>
                    <li className='privacy-list-item'>
                        <strong>Personal Information:</strong> We collect your email address, username, and details
                        about the number of conversations and groups you participate in within the Birbli app.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>End-to-End Encryption:</strong> All messages, including media shared within the app, are
                        end-to-end encrypted and stored securely in our database for a period of 30 days.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Device Information and Usage Data:</strong> We do not collect or use any device
                        information or usage data.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Profile Information:</strong> Your profile information, including profile pictures and
                        background images, is stored in our database until you delete your account. Please note that
                        this information is not encrypted.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Encryption Keys:</strong> Your encryption keys are not stored in our database unless you
                        choose to export them using a password-based export method.
                    </li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>3. How We Use Your Information</h2>
                <p className='mb-3'>
                    The data we collect is used primarily to deliver and improve the Birbli service. Specifically:
                </p>
                <ul className='privacy-list'>
                    <li className='privacy-list-item'>
                        <strong>Service Provision:</strong> We utilize your data to provide the services offered by
                        Birbli, including processing transactions and managing your account.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Third-Party Services:</strong> Certain data may be shared with third-party services,
                        such as payment processors, to facilitate transactions. More details can be found in the 'Data
                        Sharing' section below.
                    </li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>4. Data Sharing</h2>
                <p className='mb-3'>
                    To ensure seamless payment processing and account management, Birbli may share limited personal
                    information with third parties:
                </p>
                <ul className='privacy-list'>
                    <li className='privacy-list-item'>
                        <strong>Payment Processing:</strong> Your email address and username are shared with Stripe, our
                        payment processor, solely for the purpose of processing transactions. Stripe will not use this
                        information to contact you for marketing purposes.
                    </li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>5. User Rights and Data Management</h2>
                <p className='mb-3'>
                    We respect your rights to access and control your personal data:
                </p>
                <ul className='privacy-list'>
                    <li className='privacy-list-item'>
                        <strong>Data Requests:</strong> Users can request access to their personal data by emailing us
                        at <a href='mailto:birb@birbli.com'>birb@birbli.com</a>.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Account Deletion:</strong> Users can delete their accounts directly through the Birbli
                        app. After account deletion, your information will be retained for 30 days, during which you
                        can request your data. After this period, all user-provided data will be permanently deleted
                        from our databases.
                    </li>
                </ul>
            </section>

            <section className='mb3'>
                <h2 className='fw-bold'>6. Security Measures</h2>
                <p className='mb-3'>
                    Birbli takes security seriously and employs robust measures to protect your information:
                </p>
                <ul className='privacy-list'>
                    <li className='privacy-list-item'>
                        <strong>Encryption Management:</strong> Each user is assigned an encryption key tied to their
                        account, which is managed by the user. Users must securely store their encryption key and can
                        transfer it to other devices using a QR code or password-based export. We do not store your
                        encryption key; if it is lost, it may render your account inaccessible.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Password-Based Export:</strong> When users choose to export their key using a
                        password-based method, the key is temporarily stored in our database, protected by the password.
                        This stored key is deleted once the user imports it to a new device. We strongly recommend using
                        the QR code-based export method for enhanced security.
                    </li>
                    <li className='privacy-list-item'>
                        <strong>Breach Notification:</strong> In the unlikely event of a data breach, Birbli will notify
                        affected users via email promptly.
                    </li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>7. Cookies and Tracking Technologies</h2>
                <p>
                    Birbli values your privacy and does not use cookies or any other tracking technologies to monitor
                    user behavior on the Birbli app or birbli.com.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>8. Children's Privacy</h2>
                <p>
                    Given the nature of our service, Birbli is intended for mature audiences and is strictly for users
                    aged 18 and over.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>9. Changes to the Privacy Policy</h2>
                <p>
                    We may update our Privacy Policy from time to time to reflect changes in our practices or for other
                    operational, legal, or regulatory reasons. Any significant changes will be communicated via <a
                    href='https://birbli.com/privacy'>birbli.com/privacy</a>, where the most current version of the
                    policy will always be available.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>10. Contact Information</h2>
                <p>
                    If you have any questions, concerns, or requests regarding this Privacy Policy or your personal
                    data, please do not hesitate to reach out to us at <a
                    href='mailto:birb@birbli.com'>birb@birbli.com</a>.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>12. Payments and Financial Data</h2>
                <p>
                    Birbli partners with Stripe to handle all payment transactions securely. We do not store your
                    payment information directly; instead, Stripe manages this data in accordance with their privacy
                    policy. For more information on how your payment data is handled, please refer to <a
                    href='https://stripe.com/privacy' target='_blank' rel='noopener noreferrer'>Stripe's Privacy
                    Policy</a>.
                </p>
            </section>
        </div>
    );
};
